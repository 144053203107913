/* SkillsSection.css */

.skills-section {
  max-width: 1200px;
  margin: 50px auto;
}

.skills-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.skill-item {
  border: 1px solid #646464;
  border-radius: 10px;
  opacity: 0;
}

.skill-item.right {
  transform: translateX(30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.skill-item.left {
  transform: translateX(-30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.skill-item.top {
  transform: translateY(-30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.skill-item.selected {
  border-radius: 10px;
  border: 1px solid #cc00c5;
  color: #cc00c5;
}

.skill-item:hover {
  border-radius: 10px;
  border: 1px solid #cc00c5;
  color: #cc00c5;
}

.skill-item.animate {
  opacity: 1;
  transform: translateX(0);
  transform: translateY(0);
}

.skill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;
}

.skill-list-item {
  width: 120px;
  height: 170px;
  margin: 15px;
  padding: 5px;
  border: 0px solid #ccc;
  border-radius: 10px;
  text-align: center;
}

.skill-list-item img {
  max-width: 100px;
  width: auto;
  height: 64px;
  margin: 10px auto;
}

.skill-list-item h3 {
  margin-top: 10px;
  font-size: 10px;
}

.skill-list-item p {
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
}

.skills p {
  font-size: 16px;
  margin-bottom: 20px;
}

.skills {
  opacity: 0;
  padding-top: 40px;
  transform: translateY(30px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.skills.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Media query for mobile */
@media (max-width: 767px) {
  .skills-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .skill-item {
    opacity: 0;
  }

  .skill-item.right {
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .skill-item.left {
    transform: translateY(-30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .skill-item.top {
    transform: translateX(-30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }

  .skill-item.animate {
    opacity: 1;
    transform: translateX(0);
    transform: translateY(0);
  }

  .skill-list-item {
    margin: 5px;
  }
}
