
.floating-menu {
  position: fixed;
  top: 50%;
  right: -50px;
  transform: translate(-120%, -50%);
  width: 50px;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid #9D9D9D;
  transition: transform 0.3s ease-in-out;
  padding: 10px 0;
  z-index: 99;
}

.floating-menu.open {
  transform: translate(0, -50%);
}

.toggle-button {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 99;
}

.menu-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu-items li {
  padding: 5px 0px;
}

.menu-items button {
  color: #FFFFFF;
}

.menu-items button:hover {
  color: #cc00c5;
}

.menu-items li .active-link button{
  color: #cc00c5;
}