/* App.css (or your main CSS file) */

/* ... (previous styles) */

.featured-certificates-section {
  padding: 40px 0;
  text-align: center;
}

.certificate-elements {
  justify-content: center;
  margin-top: 20px;
}

.featured-button{
  border: 1px solid #646464;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
}

.featured-button:hover {
  border: 1px solid #cc00c5;
}

.portfolio-nav {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portafolio-nav .li{
  list-style-type: none;
}

.filter-button{
  padding: 0.5rem 1rem;
  margin: 5px;
  font-size: medium;
  border: 1px solid #cc00c5;
  border-radius: 25px;
}

.filter-button:hover{
  color: #FFFFFF !important;
  background-color: #cc00c5;
  cursor: pointer;
}

.filter-button:focus{
  color: #FFFFFF !important;
  background-color: #cc00c5;
}

.filter-button:active{
  color: #FFFFFF !important;
  background-color: #cc00c5;
}

.active{
  color: #FFFFFF !important;
  background-color: #cc00c5;
}

.portafolio-row{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}