
.experience-section {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.item {
  text-align: center;
  flex-basis: 30%; /* Initial width for desktop view */
}

.item h3 {
  font-size: 45px;
  line-height: 45px;
  font-weight: bold;
  margin-bottom: 20px;
}

.item p {
  font-size: medium;
  font-weight: bold;
  color: #888;
  text-transform: uppercase 
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .experience-section {
    flex-direction: column; /* Display items in a single column */
    align-items: center;
  }

  .item {
    margin-bottom: 40px;
  }
}
