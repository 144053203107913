/* Footer.css */

.footer {
  background-color: #000000;
  padding: 20px;
  text-align: center;
}

.disclaimer {
  font-size: 14px;
  margin-bottom: 10px;
  color: #c2c1c1;
}

.privacy-policy a {
  text-decoration: none;
  color: #c2c1c1;
}

.privacy-policy a:hover {
  color: #a900a3;
}

.social-icons {
  font-size: 24px;
  margin-bottom: 20px;
}

.social-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  border-color: #c2c1c1;
  color: #c2c1c1;
  border: 1px solid;
  margin: 0 10px;
}

.social-icons a:hover {
  color: #a900a3;
}

.social-icons svg{
  margin: auto auto;
  height: 100%;
}