/* SkillElement.css */

.skill-element {
  padding: 20px;
  min-height: 150px;
  height: 100%;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

.skill-element h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.skill-element p {
  font-size: 14px;
  color: #999999;
  margin-bottom: 10px;
}

.percentage {
  font-size: 14px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
