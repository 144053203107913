/* App.css (or your main CSS file) */

/* ... (previous styles) */

.featured-projects-section {
  padding: 40px 0;
  text-align: center;
}

.project-elements {
  justify-content: center;
  margin-top: 20px;
}

.featured-button{
  border: 1px solid #646464;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
}

.featured-button:hover {
  border: 1px solid #cc00c5;
}

.project-tag {
  position: absolute;
  bottom: 50px;
  left: 10px;
  background: #cc00c5;
  padding: 0px 10px;
  border-radius: 12px;
  font-size: medium;
  z-index: 2;
}

.img-container {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), 60%, rgba(0,0,0,0.7));
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.project-title{
  z-index: 3;
}