/* ContactSection.css */

.contact-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.contact-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.contact-section h3 {
  margin-top: 40px;
}

.input-container {
  margin-bottom: 20px;
}

.input-container label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left;
}

.input-container input,
.input-container textarea {
  width: 100%;
  padding-bottom: 10px;
  font-size: 16px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background: transparent;
  color: #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
}

.input-container input:focus,
.input-container input:focus-visible,
.input-container textarea:focus-visible {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background: transparent;
  color: #FFFFFF;
  border-bottom: 1px solid #cc00c5;
  outline: none;
}

.input-container textarea {
  resize: vertical;
}

.error-message {
  color: rgb(215, 0, 197);
  font-size: small;
  text-align: left;
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #cc00c5;
  color: #FFFFFF;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 40px;
  width: 250px;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.submit-button:hover:not(:disabled) {
  background-color: #a900a3;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #a900a3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
