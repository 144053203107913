.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-section {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Menu-button{
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99;
}

.Menu-button:hover{
  color: #cc00c5;
}

/* Assuming your image has a class name 'resume-image' and the text has a class name 'resume-text' */

.resume-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8% 8% 0 8%;
  width: 100%;
}

.resume-image {
  max-width: 40vw; /* Set the desired width for the image */
  margin-right: 20px; /* Adjust the spacing between the image and text */
}

.resume-text {
  flex: 1; /* Allow the text to take the remaining available space */
}

.subtitle {
  margin-top: 30px;
}

.subtitle p {
  font-size: x-large;
  text-align: left;
}

.title {
  margin-bottom: 80px;
}

.title h1{
  text-align: left;
  font-size: 65px;
  line-height: 65px;
}

.experience {
  margin-bottom: 80px;
}

.experience h1{
  text-align: center;
  font-size: 65px;
  line-height: 65px;
}

.title h3{
  text-align: left;
  font-size: xx-large;
  line-height: xx-large;
  margin-bottom: 20px;
}

.title p{
  text-align: left;
  font-size: large;
}

/* Media query for mobile */
@media (max-width: 767px) {
  .resume-section {
    flex-direction: column;
  }

  .resume-image {
    max-width: 300px;
    margin-bottom: 20px; /* Adjust the spacing between the image and text for mobile */
  }

  .resume-text {
    padding-top: 30px;
    padding-right: 40px;
    padding-bottom: 30px;
    padding-left: 0%;
  }

  .title h1{
    text-align: left;
    font-size: 40px;
    line-height: 40px;
  }

  .experience h1{
    text-align: center;
    font-size: 40px;
    line-height: 40px;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-from-right {
  animation: fadeInRight 0.5s ease-in-out;
}
.scrolldown {
  --color: white;
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin: 40px auto;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #ffffff66;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }
  40% {
    opacity: 1;
    height: 10px;
  }
  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }
  100% {
    height: 3px;
    opacity: 0;
  }
}
.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}
.chevrondown:nth-child(odd) {
  animation: pulse 500ms ease infinite alternate;
}
.chevrondown:nth-child(even) {
  animation: pulse 500ms ease infinite alternate 250ms;
}
@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.download-text{
  border: solid 1px #cc00c5;
  border-radius: 25px;
  padding: 10px;
  font-size: medium;
  width: 250px;
  margin: 40px auto;
}

.download-text a{
  font-size: large;
  margin: 0 0 0 10px;
  font-weight: bold;
}

.download-text a:hover{
  color: #cc00c5;
}

.background-none {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), 30%, rgba(0,0,0,0.8));
  background-color: transparent;
}