/* Timeline.css */

.timeline-section {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 0;
}

.timeline-item {
  display: block;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  margin-left: 30px;
}

.bullet {
  width: 10px;
  height: 10px;
  background-color: #333;
  border-radius: 50%;
  position: absolute;
  left: -24px;
  top: 25px;
  transform: translateY(-50%);
}

.active {
  background-color: #cc00c5;
}

.text-active {
  color: #cc00c5;
}

.timeline-company{
  text-align: left;
  margin-left: 30px;
  font-size: xx-large;
  margin-bottom: 0px;
  color: #cc00c5;
}

.timeline-company a:hover{
  color: #a900a3;
}

.timeline-item-years {
  font-size: 14px;
  width: 100%;
  text-align: left;
  padding-right: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  color: #FFFFFF;
}

.timeline-item-info {
  flex-grow: 1;
}

.timeline-item h3 {
  text-align: left;
  font-size: 18px;
  margin-bottom: 5px;
  color: #b4b4b4;
}

.timeline-item p {
  text-align: left;
  font-size: 14px;
  color: #777;
}

.sub-item {
  margin-left: 30px;
  margin-bottom: 30px;
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.sub-item.animate {
  opacity: 1;
  transform: translateX(0);
}

.sub-item h3 {
  font-size: 16px;
  line-height: 16px;
  margin: 0px;
  text-transform: uppercase;
}

.sub-item a {
  text-align: left;
  font-size: 14px;
  color: #777;
}

.sub-item p {
  padding-top: 5px;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  color: #777;
}

.sub-item-project {
  text-align: left;
  line-height: 14px;
}

.sub-item-project a:hover{
  color: #a900a3;
}

.line {
  width: 2px;
  height: 130%;
  background-color: #333;
  position: absolute;
  left: -20px;
  top: 30px;
}
