.certificate-card {
  width: 300px;
  height: 220px;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
  background-position-y: top;
  border-radius: 25px;
}

.certificate-content {
  width: 300px;
  height: 220px;
  padding: 10px;
}

.certificate-tag {
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 20px;
  color: black;
  padding: 4px 8px;
  margin-right: 5px;
  font-size: 12px;
}

.certificate-content a {
  display: block;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.certificate-element h3 {
  text-align: center;
  font-size: 16px;
  margin-bottom: 40px;
}

.certificate-element {
  padding: 20px;
  height: 300px;
  width: 340px;
}
